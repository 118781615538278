import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
// import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'vendorHoldMapping',
  data() {
    return {
      loading: false,
      showHistoryBtn: false,
      showValueSetModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      holdMappingLevel: {
        value: null,
        text: null
      },
      selectedBusinessUnit: {
        value: null,
        text: null
      },
      number: null,
      hold_status_flag: true,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      selectAllCheckBox: false,
      selectedRow: [],
      payloadData: [],
      vendorHoldData: [],
      vendorHoldDetails: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColum col-sm-1'
        },
        {
          key: 'supplier',
          label: 'Supplier',
          class: 'text-center'
        },
        {
          key: 'legal_entity_id',
          label: 'Legal Entity ID',
          class: 'col-fix text-center'
        },
        {
          key: 'supplier_id',
          label: 'Supplier ID',
          class: 'col-fix text-center'
        },
        {
          key: 'procurement_bu',
          label: 'Procurement BU',
          class: 'text-center'
        },
        {
          key: 'hold_flag',
          label: 'Hold Flag',
          class: 'col-fix text-center'
        },
        {
          key: 'supplier_site',
          label: 'Supplier Site',
          class: 'col-fix text-center'
        },
        {
          key: 'hold_mpng_id',
          label: 'Hold Mapping ID',
          class: 'col-fix text-center'
        },
        {
          key: 'org_name',
          label: 'Organization Name',
          class: 'text-center'
        },
        {
          key: 'supplier_number',
          label: 'Supplier Number',
          class: 'col-fix text-center'
        },
        {
          key: 'business_unit_id',
          label: 'Business Unit ID',
          class: 'col-fix text-center'
        },
        {
          key: 'supplier_site_id',
          label: 'Supplier Site ID',
          class: 'col-fix text-center'
        },
        {
          key: 'vendor_hold_criteria',
          label: 'Vendor Hold Criteria',
          class: 'col-fix text-center'
        }
      ],
      showExcelUploadModal: null,
      unsubscribe: null,
      payloadDownload: null
    };
  },
  watch: {
    currentPage: function() {
      this.getVendorHoldMapping();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorHoldMapping();
    },
    hold_status_flag: function() {
      this.getVendorHoldMapping();
    }
  },
  validations: {
    selectedLegalEntity: {
      value: {
        required
      }
    },
    holdMappingLevel: {
      value: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
        if (actionName === 'download') {
          this.loading = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payloadDownload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'gstMaster/vendorHoldMapping',
            'Vendor-Hold-Unhold-Mapping',
            () => (this.loading = false)
          );
        }
      }
    });
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FUSION_BUSINESS_UNIT) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.HOLD_MAPPING_LEVEL) {
        this.holdMappingLevel = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.FUSION_BUSINESS_UNIT) {
        this.selectedBusinessUnit = {
          value: null,
          text: null
        };
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.HOLD_MAPPING_LEVEL) {
        this.holdMappingLevel = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FUSION_BUSINESS_UNIT
      ) {
        this.selectedBusinessUnit = {
          text: item.business_unit_name,
          value: item.business_unit_id
        };
      }
      this.showValueSetModal = false;
    },
    clear() {
      this.selectedLegalEntity = this.defaultValue;
      this.holdMappingLevel = this.defaultValue;
      this.selectedBusinessUnit = this.defaultValue;
      this.hold_status_flag = false;
      this.number = null;
      this.totalRows = null;
    },
    getVendorHoldMapping() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          vendor_hold_criteria: this.holdMappingLevel.value,
          ou_id: this.selectedBusinessUnit.value,
          le_id: this.selectedLegalEntity.value,
          search_vendor_no: this.number,
          hold_flag: this.hold_status_flag
        };
        this.payloadDownload = payload;
        this.loading = true;
        this.$store
          .dispatch('gstMaster/vendorHoldMapping', payload)
          .then(response => {
            if (response.status === 200) {
              this.vendorHoldData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    updateVendorMappingDetails() {
      this.selectedRow.filter(el =>
        this.payloadData.push({
          supplier_site_id: el.supplier_site_id,
          supplier_id: el.supplier_id,
          hold_mpng_id: el.hold_mpng_id,
          le_id: el.legal_entity_id,
          ou_id: el.business_unit_id,
          vendor_hold_criteria: el.vendor_hold_criteria
        })
      );
      const payload = {
        action: this.hold_status_flag ? 'UNHOLD' : 'HOLD',
        data: this.payloadData
      };
      this.loading = true;
      this.$store
        .dispatch('gstMaster/updateVendorMapping', payload)
        .then(response => {
          if (response.status === 200) {
            // this.companyComplianceData = response.data.data.page;
            // this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectBoxChecked(flag, index, item, supplierid) {
      this.vendorHoldData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.supplier_id !== supplierid
        );
      }
      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.vendorHoldData = this.vendorHoldData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.vendorHoldData;
      } else {
        this.vendorHoldData = this.vendorHoldData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.showHistoryBtn = false;
      }
    },
    updateVendorMapping() {
      const prompt = confirm(
        `Are you sure you wan to ${
          this.hold_status_flag ? 'unhold' : 'hold'
        } the status?`
      );
      if (prompt) {
        this.updateVendorMappingDetails();
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
